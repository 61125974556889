import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitleBox from "../components/PageTitleBox"
import { PlansContent } from "../components/plans/PlansContent"

const IndexPage = () => (
  <Layout>
    <Seo
      keywords="プラン内容,料金プラン,トナリノ,デザイン,デザイン相談"
      description="各種プラン内容、提供できるサービス内容、お申込み方法についてご確認いただけます。"
      ogTitle="料金プラン・申込"
      ogDescription="トナリノの理念や、サービス運営者について"
      ogUrl="https://tonarino.co/plans"
    />
    <PageTitleBox title="料金プラン・申込" />
    <section id="plansContent">
      <PlansContent />
    </section>
  </Layout>
)

export default IndexPage
