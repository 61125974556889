import React, { useState } from 'react'
import { Link } from "gatsby"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export const PlansContent = () => {

  return (
    <>
      <div className="container pageBodyBox">
        <section id="description" className="mb-4">
          <p className="mb-0">
            必ず<Link to="/rules">利用規約</Link>をご確認の上、お申し込みください。<br />
            お申し込みいただいた際は、上記に同意いただいたとみなします。
          </p>
        </section>
        <section id="myTab" role="tablist" className="mb-5">
          <h2 className="fs-5 mb-3">メニュー内容</h2>
          <ul class="nav flex-column m-0 p-0 bg-light p-3">
            <li class="nav-item border-bottom border-gray pb-3" role="presentation">
              <a class="nav-link text-black-50" href="#printingSpecification">仕様相談プラン</a>
            </li>
            <li class="nav-item border-bottom border-gray pb-3" role="presentation">
              <a class="nav-link text-black-50" href="#designImprovingBook">
                表紙提案プラン（同人誌）</a>
            </li>
            <li class="nav-item border-bottom border-gray pb-3" role="presentation">
              <a class="nav-link text-black-50" href="#designImproving">
                デザイン提案プラン（一般向け）</a>
            </li>
            <li class="nav-item border-bottom border-gray pb-3" role="presentation">
              <a class="nav-link text-black-50" href="#assetProduction">アセット制作プラン</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link text-black-50" href="#websiteProposal">その他</a>
            </li>
          </ul>
        </section>
        <section id="printingSpecification" className="mb-5">
          <h2 className="fs-2 mt-0 fw-bold mb-2 border-start ps-3 border-5 border-primary">
            仕様相談プラン<br />
            <small>1回/1,000〜3,000円程度</small>
          </h2>
          <p className="mb-3">
            「このイラストにはどんな特殊紙が合うだろう」「こんな仕様をやってみたいけれど、どの印刷があうか知りたい」そんな疑問を気軽にご相談いただけるプランです。<br />
            <strong>表紙提案プランのオプションとしてオーダーいただくことも可能です。</strong><br />

            ご提案した内容を採用するかはご相談者様におまかせします。（その後、別の本でご提案した印刷所をご利用いただくのもOKです）お気軽にご相談ください！
          </p>
          <div className="border border-3 border-primary p-3 mb-3">
            <div className="row align-items-center mb-3 border-bottom border-gray pb-3">
              <div className="col-12 col-md-3 text-center">
                <h4 className="rounded text-white p-1 small bg-primary text-gray mb-2 mb-md-0">すでに依頼する印刷所が決まっている方</h4>
              </div>
              <div className="col-12 col-md-9">
                <ul className="mb-0">
                  <li className="mb-0">作った表紙に合わせてどんなオプションが合うかオススメしてほしい</li>
                  <li className="mb-0">決まった締切の範囲内でできそうなことを紹介してほしい</li>
                </ul>
                <div className="text-center">
                  <i className="bi bi-chevron-down fs-4 text-primary"></i>
                </div>
                <p className="mb-0">
                  依頼する印刷所が決まっている・こだわりがある方は、その印刷所のメニューのなかでより良いオプションを考えます。<br />
                  <strong>LINEもしくはBOOTHでのメッセージにてやりとりを行い、完了</strong>といたします。<br />
                  <strong>1回/1,000円</strong>にて対応いたします。
                </p>
              </div>
            </div>
            <div className="row align-items-center border-gray">
              <div className="col-12 col-md-3 text-center">
                <h4 className="rounded p-1 small bg-secondary mb-2 mb-md-0">印刷所が決まっていない、やりたいことに合わせて選びたい方</h4>
              </div>
              <div className="col-12 col-md-9">
                <ul className="mb-0">
                  <li className="mb-0">いつもより気合を入れた・凝った仕様の本をつくりたい（アンソロジー・再録本なども歓迎です！）</li>
                  <li className="mb-0">憧れのオプション（箔押し・ニス塗り・特殊製本など）をやってみたいので、部数やサイズに合わせて印刷所を紹介してほしい</li>
                  <li className="mb-0">お話の内容に合わせた印刷仕様を考えてほしい</li>
                </ul>
                <div className="text-center">
                  <i className="bi bi-chevron-down fs-4 text-primary"></i>
                </div>
                <p className="mb-2">
                  ご希望をお伺いするためLINEやBOOTHのメッセージにてやりとりをした後、Googleドキュメントでご提案を行います。<br />
                  <strong>過去実績では2〜3件の印刷所・印刷所仕様を提案しております。</strong><br />
                  <strong>1回/3,000円</strong>にて対応いたします。
                </p>
                <h4 class="mb-2">過去の提案スクリーンショット</h4>
                <div className="row gx-2">
                  <div className="col">
                    <Zoom>
                      <img
                        src="/images/sample/doc_1.png"
                        className="mw-100 mb-0 border"
                      />
                    </Zoom>
                  </div>
                  <div className="col">
                    <Zoom>
                      <img
                        src="/images/sample/doc_2.png"
                        className="mw-100 mb-0 border"
                      />
                    </Zoom>
                  </div>
                  <div className="col">
                    <Zoom>
                      <img
                        src="/images/sample/doc_3.png"
                        className="mw-100 mb-0 border"
                      />
                    </Zoom>
                  </div>
                </div>
                <p className="mb-0">このような形で、ご相談者様の希望に合わせて印刷所や装丁の仕様をご提案いたします。</p>
              </div>
            </div>
          </div>
          <p>
            記載している金額は1発注・1冊分の最低金額です。同時に2冊発行される・グッズの相談なども行いたい、などのご要望も承れますが、作業内容によって変動いたします。詳しくはご相談ください。
          </p>
        </section>
        <section id="designImprovingBook" className="mb-5">
          <h2 className="fs-2 mt-0 fw-bold mb-2 border-start ps-3 border-5 border-primary">
            表紙提案プラン<small>（同人誌）</small><br />
            <small>1回/<s>6,000円〜</s>→5,000円〜</small>
          </h2>
          <p>
            使いたいイラストや素材を見せていただき提案するほか、私ならこうするなどの案を提案させていただくプランです。同人誌の「好きなものを非営利で頒布したい」という文化を考慮し、少し価格を抑えて提供いたします。<br />
            すでに制作中のデザインに対するレビューも可能です。提案を採用するか否かはご随意に。リテイクは一回まで受け付けます（フルリテイクは原則お受けできません）。<br />
            各サンプル画像をクリックするとそれぞれ大きく確認できます。
          </p>
          <div className="row align-items-center g-0 mb-2">
            <div className="col-5">
              <Zoom>
                <img
                  src="/images/sample/advice_book_before.jpg"
                  width="400"
                  className="mw-100 mb-0"
                />
              </Zoom>
            </div>
            <div className="col-2 text-center">
              <i className="bi bi-chevron-right fs-1 text-primary"></i>
            </div>
            <div className="col-5">
              <Zoom>
                <img
                  src="/images/sample/advice_book_after.jpg"
                  width="400"
                  className="mw-100 mb-0 border"
                />
              </Zoom>
            </div>
          </div>
          <div className="border border-3 border-primary mb-3">
            <Zoom>
                <img
                  src="/images/sample/advice_book.jpg"
                  className="w-100 mb-0 align-text-bottom"
                />
              </Zoom>
          </div>
          <p>
            <span className="text-danger">お渡しするデータは静止画のみ、サービスロゴの透かしを入れた状態でお渡しします。</span>追加で使用した素材がある場合は、素材の元URLを提供します。<br />
            元データをご希望の方（+1,000円〜）や、実際にそのデザインを使いたい・発注したいという場合はオプション（+3,000円〜）でご依頼いただくことも可能です。<br />
            最低金額はサンプルと同等量（1点）の場合です。詳しくはご相談ください。
          </p>
        </section>
        <section id="designImproving" className="mb-5">
          <h2 className="fs-2 mt-0 fw-bold mb-2 border-start ps-3 border-5 border-primary">
            デザイン提案プラン<small>（一般向け）</small><br />
            <small>1回/6,000円〜</small>
          </h2>
          <p>
            制作中のデザインを見せていただき赤入れを行ったり、私ならこうするなどの案を提案させていただくプランです。提案を採用するか否かはご随意に。リテイクは一回まで受け付けます（フルリテイクは原則お受けできません）。<br />
            ノンデザイナーの方がデザインレビューを受けたい場合や、デザイナーの方がもう一案を考えたいときなどにもご利用ください。
            各サンプル画像をクリックするとそれぞれ大きく確認できます。
          </p>
          <h3 class="mb-2">キャンペーン用チラシ</h3>
          <div className="row align-items-center g-0 mb-2">
            <div className="col-5">
              <Zoom>
                <img
                  src="/images/sample/advice_flyer_before.jpg"
                  width="400"
                  className="mw-100 mb-0"
                />
              </Zoom>
            </div>
            <div className="col-2 text-center">
              <i className="bi bi-chevron-right fs-1 text-primary"></i>
            </div>
            <div className="col-5">
              <Zoom>
                <img
                  src="/images/sample/advice_flyer_after.jpg"
                  width="400"
                  className="mw-100 mb-0"
                />
              </Zoom>
            </div>
          </div>
          <div className="border border-3 border-primary mb-4">
            <Zoom>
                <img
                  src="/images/sample/advice_flyer.jpg"
                  className="w-100 mb-0 align-text-bottom"
                />
              </Zoom>
          </div>
          <h3 class="mb-2">店舗オープン用のダイレクトメール</h3>
          <div className="row align-items-center g-0 mb-2">
            <div className="col-5">
              <Zoom>
                <img
                  src="/images/sample/advice_dm_before.jpg"
                  width="400"
                  className="mw-100 mb-0 border"
                />
              </Zoom>
            </div>
            <div className="col-2 text-center">
              <i className="bi bi-chevron-right fs-1 text-primary"></i>
            </div>
            <div className="col-5">
              <Zoom>
                <img
                  src="/images/sample/advice_dm_after.jpg"
                  width="400"
                  className="mw-100 mb-0 border"
                />
              </Zoom>
            </div>
          </div>
          <div className="border border-3 border-primary mb-4">
            <Zoom>
                <img
                  src="/images/sample/advice_dm.jpg"
                  className="w-100 mb-0 align-text-bottom"
                />
              </Zoom>
          </div>
          <p>
            <span className="text-danger">お渡しするデータは静止画のみ、サービスロゴの透かしを入れた状態でお渡しします。</span>追加で使用した素材がある場合は、素材の元URLを提供します。<br />
            元データをご希望の方（+1,000円〜）や、実際にそのデザインを使いたい・発注したいという場合はオプション（+3,000円〜）でご依頼いただくことも可能です。<br />
            最低金額はサンプルと同等量（1点）の場合です。詳しくはご相談ください。
          </p>
        </section>
        <section id="assetProduction" className="mb-5">
          <h2 className="fs-2 mt-0 fw-bold mb-2 border-start ps-3 border-5 border-primary">
            アセット制作プラン<br />
            <small>1回/10,000円〜</small>
          </h2>
          <p>
            スムーズな動画投稿や、広告運用など、定期的な更新が必要なものに対して、その都度デザイナーに連絡するのは面倒だったり、費用がかかると感じていませんか。<br />
            定期的な更新がご自身で行えるよう、Instagram・Twitter・Facebook等の広告バナーやYouTubeのサムネ制作の際に必要となるアセットを制作いたします。
          </p>
          <p>
            以下のサンプルのように、フォントの内容・色味・写真などを調整すればバリエーションがつくれるデータセットを「アセット」と呼んでいます。<br />
            PhotoshopやCanva等、なるべく編集しやすい方法をご提案いたしますので、ご相談ください。
          </p>
          <h3 class="mb-2">料理系チャンネル</h3>
          <div className="row align-items-center g-2 mb-2">
            <div className="col-6">
              <Zoom>
                <img
                  src="/images/sample/asset_thumbnail_cook1.jpg"
                  width="400"
                  className="mw-100 mb-0"
                />
              </Zoom>
            </div>
            <div className="col-6">
              <Zoom>
                <img
                  src="/images/sample/asset_thumbnail_cook2.jpg"
                  width="400"
                  className="mw-100 mb-0"
                />
              </Zoom>
            </div>
          </div>
          <h3 class="mb-2">ゲーム系チャンネル</h3>
          <div className="row align-items-center g-2 mb-2">
            <div className="col-6">
              <Zoom>
                <img
                  src="/images/sample/asset_thumbnail_game1.jpg"
                  width="400"
                  className="mw-100 mb-0"
                />
              </Zoom>
            </div>
            <div className="col-6">
              <Zoom>
                <img
                  src="/images/sample/asset_thumbnail_game2.jpg"
                  width="400"
                  className="mw-100 mb-0"
                />
              </Zoom>
            </div>
          </div>
          <h3 class="mb-2">Instgram広告バナー</h3>
          <div className="row align-items-center g-2 mb-2">
            <div className="col-6">
              <Zoom>
                <img
                  src="/images/sample/asset_banner_square1.jpg"
                  width="400"
                  className="mw-100 mb-0"
                />
              </Zoom>
            </div>
            <div className="col-6">
              <Zoom>
                <img
                  src="/images/sample/asset_banner_square2.jpg"
                  width="400"
                  className="mw-100 mb-0"
                />
              </Zoom>
            </div>
          </div>
          <p>
            アセット制作後のレビューが必要な場合は「単発アドバイスプラン」と組み合わせていただくのをおすすめします。<br />
            最低金額はサンプルと同等量（1点）の場合です。詳しくはご相談ください。
          </p>
        </section>
        <section id="websiteProposal" className="mb-5">
          <h2 className="fs-2 mt-0 fw-bold mb-2 border-start ps-3 border-5 border-primary">
            サイトの基礎構築プラン<br />
            <small>1案件/30,000円〜</small>
          </h2>
          <p>
            サイトがまだないコンテンツをSTUDIO・BASE・STORESにて構築し、ご自身で更新していただくプランです。ポートフォリオサイトや企業サイト、ECサイトなどをご自身でつくりたいけれど一から一人で作るのは不安、という方におすすめです。詳しくはご相談ください。
          </p>
        </section>
        {/*
        <section id="websiteCheck" className="mb-5">
          <h2 className="fs-2 mt-0 fw-bold mb-2 border-start ps-3 border-5 border-primary">
            サイトやアプリのUI・UX外部評価プラン<br />
            <small>1案件/30,000円〜</small>
          </h2>
          <p>
            現在運用中、もしくは制作中のサイトやアプリを見せていただき、UIやUX部分の評価、改善点のリストアップなどを行わせていただきます。詳しくはご相談ください。
          </p>
        </section>
        <section id="websiteProduction" className="mb-5">
          <h2 className="fs-2 mt-0 fw-bold mb-2 border-start ps-3 border-5 border-primary">
            サイトやアプリの制作伴走プラン<br />
            <small>1プロジェクト/50,000円〜</small>
          </h2>
          <p>
            Webサイトの構築やアプリの制作を請け負ったものの、技術的に不安な部分を誰かに相談したい。訓練校や専門学校で技術は学んだものの、実務経験が乏しいので不安。そう思われている方の相談窓口となり、一緒に問題を解決していきます。<br />
            連絡ツールはLINEのほか、ChatworkやSlack等、環境に合わせて対応可能です。<br />
            プロジェクトが長期に渡る場合はサブスクリプションでの契約をお願いする場合があります。詳しくはご相談ください。
          </p>
        </section>
        */}

        <h2 className="fs-4 mt-5 pt-5 border-top border-dark border-3 fw-bold mb-2" id="requestOrder">
          お申し込みについて
        </h2>
        <p className="mb-3">
          お申し込みはLINE・BOOTH・メールフォームにて受け付けております。<br />
          どの方法も最初のご連絡時に以下の内容をお送りください。<br />
          メッセージの対応時間は<strong>12:00〜21:00</strong>の間で随時行っております。
        </p>
        <div className="border py-2 ps-2 mb-4">
          <ul className="mb-0">
            <li className="mb-0">お名前（ふりがなも・ペンネーム可）</li>
            <li className="mb-0">ご希望のプラン</li>
            <li className="mb-0">納期（あれば、おおまかでOKです）</li>
            <li className="mb-0">ご相談内容（なるべく詳しく書いてください）</li>
          </ul>
        </div>
        <h3 className="fs-5 mt-0 fw-bold mb-2" id="requestOrderLINE">
          LINEでのお申込みをご希望の方
        </h3>
        <p class="mb-2">
          友だち追加後、上記の内容を送信してください。折返しご連絡差し上げます。<br />
          プランのご相談など、検討中の場合もお気軽にご相談ください。
          （トナリノアカウントとのやりとりであり、アドバイザー本人と友だち登録するわけではございませんのでご安心ください）<br />
        </p>
        <div className="text-center mb-4">
          <a href="https://lin.ee/js6yGWl"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="64" border="0" className="mt-1" /></a>
        </div>

        <h3 className="fs-5 mt-0 fw-bold mb-2" id="requestOrderBOOTH">
          BOOTHでのお申込みをご希望の方
        </h3>
        <p class="mb-2">
          pixivアカウントをお持ちの方はBOOTHからのお申込みが可能です。LINEは本名で使っている、ペンネームでやりとりしたい、などの場合にもぜひ。<br />
          トナリノBOOTHページのメッセージより、友だち追加後、上記の内容を送信してください。折返しご連絡差し上げます。<br />
          お申し込み時に専用ページを作成させていただき、サービス開始となります。決済方法はBOOTHのものをお使いいただけます。
        </p>
        <div className="text-center mb-5">
          <Link to="https://tonarinodesign.booth.pm" className="btn btn-primary btn-fixed-width rounded-pill py-3" target="_blank">トナリノのBOOTHページへ</Link>
        </div>
        
        <h3 className="fs-5 mt-0 fw-bold mb-2" id="requestOrderForm">
          メールのお申込みをご希望の方
        </h3>
        <p class="text-start mb-2">
          メールでの連絡をご希望の場合は以下のフォームからお申し込みください。<br />
          ※メールでのやりとりはLINE・BOOTHよりもレスポンスが遅くなる可能性があります。
          </p>
        <div className="text-center mt-4 mb-5">
          <Link to="https://tayori.com/form/bce4102be00442293ae9c3fab28640c8eef48ea5/" className="btn btn-outline-primary btn-fixed-width rounded-pill py-3">お問い合わせフォームへ</Link>
        </div>
      </div>
    </>
  )
}
